import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
// core components
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Loading from "components/Loading";
import PipelineOverviewSummary from "components/Views/PipelineOverview/Summary";
import API from "service/api";
import PipelineOverviewCollection from "components/Views/PipelineOverview/Collection";
import PipelineOverviewTranscription from "components/Views/PipelineOverview/Transcription";
import PipelineStatusType from "enum/pipeline-status";

const PipelineOverview = () => {
  const { id: pipelineId } = useParams();
  const [loading, setLoading] = useState(false);
  const [pipelineExecuted, setPipelineExecuted] = useState(false);
  const [pipelineExecution, setPipelineExecution] = useState({});

  const execute = useCallback(() => {
    setLoading(true);
    setPipelineExecuted(true);

    API.post("/pipeline/execute", {
      pipelineId,
    })
      .then((response) => {
        toast.success("Pipeline executada com sucesso.");
        setLoading(false);
        setPipelineExecution(response.data);
      })
      .catch(() => {
        toast.error("Erro ao executar pipeline!");
        setPipelineExecution({ status: PipelineStatusType.ERROR });
        setPipelineExecuted(false);
        setLoading(false);
        return;
      });
  }, []);

  return (
    <>
      {/* Page content */}
      <Container className="mt-7" fluid>
        <Col className="" xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Pipeline {pipelineId} </h3>
                </Col>
                <Col className="text-right" xs="4"></Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row className="mb-3">
                <Col className="col-12">
                  <div className="d-flex">
                    <Button
                      color="primary"
                      style={{ fontSize: "0.7em" }}
                      disabled={pipelineExecuted}
                      onClick={execute}
                    >
                      {!pipelineExecuted
                        ? "Executar pipeline"
                        : "Pipeline executada"}
                    </Button>
                    <Button
                      color="secondary"
                      onClick={() => window.location.reload()}
                      style={{ fontSize: "0.7em" }}
                    >
                      Atualizar informações <i className="fa fa-sync"></i>
                    </Button>
                  </div>
                </Col>
              </Row>
              <Row className="mb-2">
                <Col className="col-12">
                  <PipelineOverviewSummary
                    setPipelineExecuted={setPipelineExecuted}
                    pipelineExecution={pipelineExecution}
                  />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col className="col-12">
                  <PipelineOverviewCollection />
                </Col>
              </Row>
              <Row className="mb-2">
                <Col className="col-12">
                  <PipelineOverviewTranscription />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Container>
    </>
  );
};

export default PipelineOverview;
