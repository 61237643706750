import React, { useEffect, useRef } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import { Container } from "reactstrap";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from "routes.js";
import Users from "views/Users";
import UserForm from "views/UserForm";
import Clients from "views/Clients";
import ClientForm from "views/ClientForm";
import Segments from "views/Segments";
import SegmentForm from "views/SegmentForm";
import Favorites from "views/Favorites";
import TopFavorites from "views/TopFavorites";
import FavoritesUsers from "views/FavoritesUsers";
import FavoritesUserByClient from "views/FavoritesUserByClient";
import ContentForm from "views/ContentForm.js";
import Content from "views/Content.js";
import Metrics from "views/Metric";
import MetricRuleClientList from "views/MetricRuleClientList";
import Rules from "views/Rules";
import UploadMetrics from "views/UploadMetricsFile";
import Prompts from "views/Prompts";
import PromptForm from "views/PromptForm";
import UploadAttendances from "views/UploadAttendances";
import Attendances from "views/Attendances";
import Attendance from "views/Attendance";
import MeuSputiContent from "views/ContentMeuSputi";
import Roles from "views/Roles";
import RoleForm from "views/RoleForm";
import IpGroup from "views/ipGroup";
import Templates from "views/Templates";
import TemplateForm from "views/TemplateForm";
import AnalysisProcesses from "views/AnalysisProcesses";
import AnalysisProcess from "views/AnalysisProcess";
import ReusableElements from "views/ReusableElements";
import ReusableElementForm from "views/ReusableElementForm";
import QualityAttendances from "views/QualityAttendances";
import QualityAttendance from "views/QualityAttendance";
import PromptScoreForm from "views/PromptScoreForm";
import EvaluableEventForm from "views/EvaluableEventForm";
import EvaluableEvent from "views/EvaluableEvent";
import QualityScoreRules from "views/QualityScoreRules";
import QualityContestation from "views/QualityContestation";
import ClientSummary from "views/ClientSummary";
import PipelineForm from "views/PipelineForm";
import LibraryForm from "views/LibraryForm";
import Audios from "views/Audios";
import PipelineOverview from "views/PipelineOverview";

const Admin = (props) => {
  const mainContent = useRef(null);
  const location = useLocation();

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <Sidebar {...props} routes={routes} />
      <div className="main-content" ref={mainContent}>
        <Switch>
          {getRoutes([
            {
              name: "Usuários",
              icon: "ni ni-single-02 text-yellow",
              path: "/users",
              layout: "/admin",
              component: Users,
            },

            {
              name: "Formulário de Usuários",
              icon: "ni ni-single-02 text-yellow",
              path: "/users-form",
              layout: "/admin",
              component: UserForm,
            },
            {
              name: "Clientes",
              icon: "ni ni-single-02 text-yellow",
              path: "/clients",
              layout: "/admin",
              component: Clients,
            },
            {
              name: "Cadastro de Clientes",
              icon: "ni ni-collection text-blue",
              path: "/clients-form",
              layout: "/admin",
              component: ClientForm,
            },
            {
              name: "Segmentos",
              icon: "ni ni-single-02 text-yellow",
              path: "/segments",
              layout: "/admin",
              component: Segments,
            },
            {
              name: "Cadastro de Segmentos",
              icon: "ni ni-collection text-blue",
              path: "/segments-form",
              layout: "/admin",
              component: SegmentForm,
            },
            {
              name: "Favorites",
              icon: "ni ni-collection text-blue",
              path: "/favorites",
              layout: "/admin",
              component: Favorites,
            },
            {
              name: "Top Favoritos",
              icon: "ni ni-trophy text-purple",
              path: "/top-favorites",
              layout: "/admin",
              component: TopFavorites,
            },
            {
              name: "Usuários Favoritos",
              icon: "ni ni-trophy text-purple",
              path: "/users-favorites",
              layout: "/admin",
              component: FavoritesUsers,
            },
            {
              name: "Usuários Por Cliente",
              icon: "ni ni-trophy text-purple",
              path: "/users-clients",
              layout: "/admin",
              component: FavoritesUserByClient,
            },
            {
              path: "/formulario-conteudo",
              name: "Adicionar conteúdo",
              icon: "ni ni-bulb-61 text-red",
              component: ContentForm,
              layout: "/admin",
            },
            {
              path: "/conteudos",
              name: "Conteúdos",
              icon: "ni ni-bullet-list-67 text-red",
              component: Content,
              layout: "/admin",
            },
            {
              name: "Metricas",
              icon: "ni ni-user-run text-pink",
              path: "/metrics",
              layout: "/admin",
              component: Metrics,
            },
            {
              name: "Selecione um cliente",
              icon: "ni ni-settings text-pink",
              path: "/clients-rules",
              layout: "/admin",
              component: MetricRuleClientList,
            },
            {
              name: "Regras",
              icon: "ni ni-settings text-pink",
              path: "/rules",
              layout: "/admin",
              component: Rules,
            },
            {
              name: "Carregar arquivo de metricas",
              icon: "ni ni-cloud-upload-96 text-#404040",
              path: "/upload-metrics",
              layout: "/admin",
              component: UploadMetrics,
            },
            {
              name: "Processamento",
              icon: "ni ni-sound-wave text-purple",
              path: "/analysis-processes/:id",
              layout: "/admin",
              component: AnalysisProcess,
            },
            {
              name: "Status de processamentos",
              icon: "ni ni-sound-wave text-purple",
              path: "/analysis-processes",
              layout: "/admin",
              component: AnalysisProcesses,
            },
            {
              name: "Carregar atendimentos",
              icon: "ni ni-cloud-upload-96 text-purple",
              path: "/upload-attendances",
              layout: "/admin",
              component: UploadAttendances,
            },
            {
              name: "Atendimento",
              icon: "ni ni-atom text-purple",
              path: "/attendances/:id",
              layout: "/admin",
              component: Attendance,
            },
            {
              name: "Atendimentos analisados",
              icon: "ni ni-atom text-purple",
              path: "/attendances",
              layout: "/admin",
              component: Attendances,
            },
            {
              name: "Pipeline overview",
              icon: "ni ni-world-2 text-purple",
              path: "/pipeline/:id",
              layout: "/admin",
              component: PipelineOverview,
            },
            {
              name: "Configurar pipeline",
              icon: "ni ni-world-2 text-purple",
              path: "/pipeline",
              layout: "/admin",
              component: PipelineForm,
            },
            {
              name: "Prompts",
              icon: "ni ni-books text-purple",
              path: "/prompts",
              layout: "/admin",
              component: Prompts,
            },
            {
              name: "Cadastro de Prompts",
              icon: "ni ni-collection text-purple",
              path: "/prompts-form",
              layout: "/admin",
              component: PromptForm,
            },
            {
              path: "/conteudos-meu-sputi",
              name: "Conteúdos",
              icon: "ni ni-bullet-list-67 text-red",
              component: MeuSputiContent,
              layout: "/admin",
            },
            {
              name: "Cargos",
              icon: "ni ni-single-02 text-yellow",
              path: "/roles",
              layout: "/admin",
              component: Roles,
            },
            {
              name: "Cadastro de cargo",
              icon: "ni ni-collection text-green",
              path: "/roles-form",
              layout: "/admin",
              component: RoleForm,
            },
            {
              path: "/ip-group",
              name: "Grupo de ips",
              icon: "ni ni-bullet-list-67",
              component: IpGroup,
              layout: "/admin",
            },
            {
              name: "Templates",
              icon: "ni ni-books text-purple",
              path: "/templates",
              layout: "/admin",
              component: Templates,
            },
            {
              name: "Cadastro de Template",
              icon: "ni ni-collection text-purple",
              path: "/template-form",
              layout: "/admin",
              component: TemplateForm,
            },
            {
              name: "Elementos reutilizáveis",
              icon: "ni ni-books text-purple",
              path: "/reusable-elements",
              layout: "/admin",
              component: ReusableElements,
            },
            {
              name: "Cadastro de elementos reutilizáveis",
              icon: "ni ni-books text-purple",
              path: "/reusable-element-form",
              layout: "/admin",
              component: ReusableElementForm,
            },
            {
              name: "Atendimentos analisados para qualidade",
              icon: "ni ni-atom text-#404040",
              path: "/quality-attendances",
              layout: "/admin",
              component: QualityAttendances,
            },
            {
              name: "Atendimento para qualidade",
              icon: "ni ni-atom text-#404040",
              path: "/quality-attendance/:id",
              layout: "/admin",
              component: QualityAttendance,
            },
            {
              name: "Cadastro de Score",
              icon: "ni ni-atom text-#404040",
              path: "/prompt-score-form",
              layout: "/admin",
              component: PromptScoreForm,
            },
            {
              name: "Cadastro de Eventos Avaliáveis",
              icon: "ni ni-collection text-muted",
              path: "/evaluable-event-form",
              layout: "/admin",
              component: EvaluableEventForm,
            },
            {
              name: "Eventos Avaliáveis",
              action: "read:quality:evaluable_event",
              icon: "ni ni-books text-muted",
              path: "/evaluable-event",
              layout: "/admin",
              component: EvaluableEvent,
            },
            {
              name: "Regras de Score",
              action: "read:quality:score_rule",
              icon: "ni ni-collection text-muted",
              path: "/score-rules",
              layout: "/admin",
              component: QualityScoreRules,
            },
            {
              name: "Revisar Contestações",
              action: "read:quality:pending_contestation",
              icon: "fab fa-stack-exchange text-muted",
              path: "/contestations",
              layout: "/admin",
              component: QualityContestation,
            },
            {
              name: "Resumo do cliente",
              icon: "fab fa-stack-exchange text-muted",
              path: "/client-summary",
              layout: "/admin",
              component: ClientSummary,
            },
            {
              name: "Cadastrar Biblioteca",
              action: "create:library",
              icon: "ni ni-books text-purple",
              path: "/library-form",
              layout: "/admin",
              component: LibraryForm,
            },
            {
              name: "Listagem de Áudios",
              icon: "fab fa-stack-exchange text-muted",
              path: "/attendance-audios",
              layout: "/admin",
              component: Audios,
            },
          ])}
          <Redirect from="*" to="/admin/index" />
        </Switch>
        <Container fluid>
          {location.pathname === "/admin/index" && (
            <Container className="mt-8">
              <h1>Bem vindo ao Sputinik Admin</h1>
              <h2>Selecione uma opção a esquerda para começar.</h2>
            </Container>
          )}
        </Container>
      </div>
    </>
  );
};

export default Admin;
